import { init, BrowserTracing } from "@sentry/browser";

export default function initSentry() {
  // vite substitutes this variable
  if (import.meta.env.PROD) {
    init({
      dsn: "https://3299bfa116048963ff37a54ab5f008a9@o4505822234411008.ingest.sentry.io/4505823292882944",

      // todo: release

      integrations: [
        new BrowserTracing({
          beforeNavigate: (context) => {
            return {
              ...context,
              name: location.pathname
                .replace(/^\/b\/[a-zA-Z0-9]+\//, "/b/{bundle_public_id}/")
                .replace(/^\/r\/[a-zA-Z0-9]+\//, "/r/{run_public_id}/"),
            };
          },
        }),
      ],

      tracesSampleRate: 0.1,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://torrentdyne.com"],
    });
  }
}
