import keyCleanup from "./key_cleanup.ts";
import { CommonData } from "../shared_types/common_data.gen.schema";
import initSentry from "./init_sentry.ts";

export default function runCommon() {
  const commonDataEl = document.getElementById("tdyne-common-data");
  if (commonDataEl === null) {
    throw new Error("tdyne-common-data element not found");
  }
  const commonDataStr = commonDataEl.textContent;
  if (commonDataStr === null) {
    throw new Error("empty tdyne-common-data element");
  }
  let commonData = JSON.parse(commonDataStr) as CommonData;

  initSentry();
  keyCleanup(commonData);
}
